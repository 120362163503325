<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="12">
          <h4 class="rounded bg-primary text-white"
            style="text-align: center; font-size: 20px; font-weight: bold; margin-bottom: 25px; padding: 10px 8px 8px 15px">
            Detecção de Movimento
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <div class="profile-img" style="text-align: center; right: 20px; top: 10px" v-if="!showImage && !videoPrint && !finalizaEvent">
            <span class="b-avatar badge-secondary rounded" style="width: 360px; height: 320px">
              <feather-icon icon="UserIcon" fill="currentColor" size="80" />
            </span>
          </div>
          <img v-if="videoPrint && finalizaEvent" :src="videoPrint" class="img-fluid rounded"
            style="max-height: 320px" />
          <transition name="fade">
            <div v-show="!videoLoaded && messageStart"></div>
          </transition>
          <transition name="fade">
            <div v-show="showImage && videoLoaded" class="rounded-gray-box mb-2">
              Enquadre seu rosto para iniciar a detecção de movimento
            </div>
          </transition>
          <div v-show="showImage" class="video-container" style="text-align: center; max-width: 100%; overflow: hidden">
            <div style="
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        ">
              <transition name="fade">
                <LottieAnimation v-show="showOlheCima" class="video-overlay-top" :animation-data="animationJSON"
                  :auto-play="true" :loop="true" :speed="1" ref="anim" />
              </transition>
              <transition name="fade">
                <LottieAnimation v-show="showOlheBaixo && !showOlheCima" class="video-overlay-down"
                  :animation-data="animationJSON" :auto-play="true" :loop="true" :speed="1" ref="anim" />
              </transition>
              <transition name="fade">
                <LottieAnimation v-show="showOlheDireita && !showOlheBaixo" class="video-overlay-right"
                  :animation-data="animationJSON" :auto-play="true" :loop="true" :speed="1" ref="anim" />
              </transition>
              <transition name="fade">
                <LottieAnimation v-show="showOlheEsquerda && !showOlheDireita" class="video-overlay-left"
                  :animation-data="animationJSON" :auto-play="true" :loop="true" :speed="1" ref="anim" />
              </transition>
              <video v-if="!finalizaEvent" id="webcam" ref="webcam" autoplay playsinline
                @loadedmetadata="handleVideoLoaded"></video>
            </div>
            <canvas v-show="false" class="output_canvas" id="output_canvas" ref="output_canvas"
              style="position: absolute; left: 0; top: 0"></canvas>
            <div class="blend-shapes" v-show="false">
              <ul class="blend-shapes-list" ref="video-blend-shapes" id="video-blend-shapes"></ul>
            </div>
          </div>

          <div style="text-align: center; margin-top: 20px">
            <b-button v-if="!webcamRunning && !finalizaEvent" id="webcamButton" ref="webcamButton"
              class="mdc-button mdc-button--raised btn-success" @click="enableCam">
              <span class="mdc-button__ripple"></span>
              <span class="mdc-button__label">Iniciar detecção</span>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import vision from './tasks.js';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { faces } from '@/views/components/compare-faces/CompareFaces';
import { pipe } from 'fp-ts/lib/function';
import * as TaskEither from 'fp-ts/TaskEither';
import * as Either from 'fp-ts/Either';
import animationJSON from '@/assets/animation_lmtbzxvw.json';
import LottieAnimation from 'lottie-web-vue';
import { Buffer } from 'buffer';

const { FaceLandmarker, FilesetResolver, DrawingUtils } = vision;
export default {
  components: {
    LottieAnimation,
  },
  props: {
    selfie: {},
  },
  data() {
    return {
      loading: false,
      animationJSON: animationJSON,
      posicionouQueixo: false,
      modalShow: false,
      faceLandmarker: undefined,
      demosSection: undefined,
      imageBlendShapes: undefined,
      videoBlendShapes: undefined,
      runningMode: 'IMAGE',
      enableWebcamButton: undefined,
      webcamRunning: false,
      showImage: false,
      videoLoaded: false,
      messageStart: false,
      videoWidth: 480,
      sorriu: false,
      levantou: false,
      piscou: false,
      abaixou: false,
      showOlheCima: false,
      showOlheBaixo: false,
      showOlheDireita: false,
      showOlheEsquerda: false,
      rotacionouDireito: false,
      rotacionouEsquerdo: false,
      faceAproved: false,
      lastVideoTime: -1,
      predictWebcam: undefined,
      videoPrint: undefined,
      finalizaEvent: false,
      lottieOptions: {
        path: 'animation_lmtbzxvw.json',
        loop: true,
        autoPlay: true,
        loopDelayMin: 2.5,
        loopDelayMax: 5,
        speed: 1,
        width: 256,
        height: 256,
      },
    };
  },
  async mounted() {
    const component = this;
    this.demosSection = this.$refs['demos'];
    this.imageBlendShapes = this.$refs['image-blend-shapes'];
    this.videoBlendShapes = this.$refs['video-blend-shapes'];
    this.video = this.$refs['webcam'];
    this.canvasElement = this.$refs['output_canvas'];
    this.canvasCtx = this.canvasElement.getContext('2d');

    const demosSection = this.$refs['demos'];
    const imageBlendShapes = this.$refs['image-blend-shapes'];
    const videoBlendShapes = this.$refs['video-blend-shapes'];

    let faceLandmarker;
    let runningMode = 'IMAGE';
    let enableWebcamButton;
    const videoWidth = 300;

    // Before we can use HandLandmarker class we must wait for it to finish
    // loading. Machine Learning models can be large and take a moment to
    // get everything needed to run.
    await this.createFaceLandmarker();
    faceLandmarker = this.faceLandmarker;

    /********************************************************************
    // Demo 2: Continuously grab image from webcam stream and detect it.
    ********************************************************************/

    const video = this.video;
    const canvasElement = this.canvasElement;
    const canvasCtx = this.canvasCtx;

    // Check if webcam access is supported.
    function hasGetUserMedia() {
      return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    }

    // If webcam supported, add event listener to button for when user
    // wants to activate it.
    if (hasGetUserMedia()) {
      // enableWebcamButton = document.getElementById("webcamButton");
      // enableWebcamButton.addEventListener("click", enableCam);
    } else {
      console.warn('getUserMedia() is not supported by your browser');
    }

    function disableCam() {
      if (component.webcamRunning === true) {
        video.removeEventListener('loadeddata', predictWebcam);
        component.webcamRunning = false;

        // Parar o stream da webcam
        const stream = video.srcObject;
        if (stream) {
          const tracks = stream.getTracks();
          tracks.forEach(track => track.stop());
          video.srcObject = null;
        }

        // Remover o evento de detecção
        component.finalizaEvent = true;
        // Adicione qualquer outra lógica que você precise para desativar a webcam
        // Por exemplo, ocultar elementos de interface relacionados à webcam.
      }
    }

    let results = undefined;
    const drawingUtils = new DrawingUtils(canvasCtx);
    async function predictWebcam() {
      if (!component.webcamRunning) {
        return;
      }
      try {
        const radio = video.videoHeight / video.videoWidth;
        video.style.width = videoWidth + 'px';
        video.style.height = videoWidth * radio + 'px';
        canvasElement.style.width = videoWidth + 'px';
        canvasElement.style.height = videoWidth * radio + 'px';
        canvasElement.width = video.videoWidth;
        canvasElement.height = video.videoHeight;
        // Now let's start detecting the stream.
        if (runningMode === 'IMAGE') {
          runningMode = 'VIDEO';
          await faceLandmarker.setOptions({ runningMode: runningMode });
        }
        let startTimeMs = performance.now();
        if (component.lastVideoTime !== video.currentTime) {
          component.lastVideoTime = video.currentTime;
          results = faceLandmarker.detectForVideo(video, startTimeMs);
        }
        if (results.faceLandmarks) {
          //
          for (const landmarks of results.faceLandmarks) {
            drawingUtils.drawConnectors(
              landmarks,
              FaceLandmarker.FACE_LANDMARKS_TESSELATION,
              { color: '#C0C0C070', lineWidth: 1 }
            );
            drawingUtils.drawConnectors(
              landmarks,
              FaceLandmarker.FACE_LANDMARKS_RIGHT_EYE,
              { color: '#FF3030' }
            );
            drawingUtils.drawConnectors(
              landmarks,
              FaceLandmarker.FACE_LANDMARKS_RIGHT_EYEBROW,
              { color: '#FF3030' }
            );
            drawingUtils.drawConnectors(
              landmarks,
              FaceLandmarker.FACE_LANDMARKS_LEFT_EYE,
              { color: '#30FF30' }
            );
            drawingUtils.drawConnectors(
              landmarks,
              FaceLandmarker.FACE_LANDMARKS_LEFT_EYEBROW,
              { color: '#30FF30' }
            );
            drawingUtils.drawConnectors(
              landmarks,
              FaceLandmarker.FACE_LANDMARKS_FACE_OVAL,
              { color: '#E0E0E0' }
            );
            drawingUtils.drawConnectors(
              landmarks,
              FaceLandmarker.FACE_LANDMARKS_LIPS,
              { color: '#E0E0E0' }
            );
            drawingUtils.drawConnectors(
              landmarks,
              FaceLandmarker.FACE_LANDMARKS_RIGHT_IRIS,
              { color: '#FF3030' }
            );
            drawingUtils.drawConnectors(
              landmarks,
              FaceLandmarker.FACE_LANDMARKS_LEFT_IRIS,
              { color: '#30FF30' }
            );
          }
        }
        drawBlendShapes(
          videoBlendShapes,
          results.faceBlendshapes,
          results.faceLandmarks
        );

        // Call this function again to keep predicting when the browser is ready.
        if (component.webcamRunning === true) {
          window.requestAnimationFrame(predictWebcam);
        }
      } catch (error) { }
    }

    this.predictWebcam = predictWebcam;
    const toast = this.$toast;

    function msg(text) {
      toast(
        {
          component: ToastificationContent,
          props: {
            title: 'Atenção',
            icon: 'BellIcon',
            text: text,
            variant: 'info',
          },
        },
        {
          position: 'top-right',
          timeout: 8000,
        }
      );
    }

    async function downloadAndConvertToBase64(url) {
      const axios = require('axios').default;

      // Realizar o download do arquivo
      const response = await axios
        .get(url, { responseType: 'arraybuffer' })
        .catch(error => {
          console.log('[downloadAndConvertToBase64] =>', error);
        });

      // Converter o arquivo em base64
      const base64Data = Buffer.from(response.data, 'binary').toString(
        'base64'
      );
      return base64Data;
    }

    async function detectaAcao(categories, pontoQueixo, landmarks) {
      const ladoEsquerdo = landmarks[0][234].z;
      const ladoDireito = landmarks[0][454].z;

      if (
        pontoQueixo.z < -0.08 &&
        !component.levantou &&
        component.posicionouQueixo &&
        component.showOlheCima
      ) {
        component.levantou = true;
        component.showOlheCima = false;

        component.faceAproved = true;

        component.levantou = true;
        component.showOlheBaixo = true;
      }

      // Seta Para Baixo
      if (
        pontoQueixo.z > 0.03 &&
        component.showOlheBaixo &&
        component.faceAproved
      ) {
        // msg('Rotacionou para baixo')
        component.abaixou = true;
        component.showOlheBaixo = false;
        component.showOlheDireita = true;
      }

      // Seta Para Direita
      if (
        ladoDireito < 0 &&
        component.showOlheDireita &&
        !component.showOlheBaixo &&
        component.faceAproved
      ) {
        // msg('Rotacionou direito')
        component.rotacionouDireito = true;
        component.showOlheDireita = false;
        component.showOlheEsquerda = true;
      }

      // Seta Para Esquerda
      if (
        ladoEsquerdo < 0 &&
        component.showOlheEsquerda &&
        !component.showOlheDireita &&
        !component.showOlheBaixo &&
        component.faceAproved
      ) {
        // msg('Rotacionou esquerdo')
        component.showOlheEsquerda = false;
        msg('Estamos realizando a validação facial. Aguarde...');

        const canvasElement = document.createElement('canvas');
        const context = canvasElement.getContext('2d');
        canvasElement.width = video.videoWidth;
        canvasElement.height = video.videoHeight;
        context.drawImage(
          video,
          0,
          0,
          canvasElement.width,
          canvasElement.height
        );

        const videoCaptured = canvasElement.toDataURL('image/jpeg');
        component.videoPrint = videoCaptured;
        let fotoSelfie = component.selfie?.foto || component.selfie?.s3url;

        let inAws = false;
        if (fotoSelfie.includes('http')) {
          inAws = true;
          fotoSelfie = await downloadAndConvertToBase64(fotoSelfie);
        }

        const { notTheSameFace, tooManyFaces, faceNotFound } =
          faces.validations;
        const compareFaces = (videoCaptured, fotoSelfie) =>
          faces.compare({
            image: {
              source: videoCaptured.split(';base64,')[1],
              target: !inAws ? fotoSelfie.split(';base64,')[1] : fotoSelfie,
            },
          });

        const validateFaces = response =>
          faces.validate(response.data, {
            notTheSameFace: notTheSameFace({ quota: 85 }),
            tooManyFaces,
            faceNotFound,
          });
        const response = await pipe(
          TaskEither.tryCatch(
            () => compareFaces(videoCaptured, fotoSelfie),
            () => 'Falha ao comparar faces.'
          ),
          TaskEither.chain(response =>
            TaskEither.fromEither(validateFaces(response))
          )
        )();

        if (response._tag === 'Right') {
          component.faceAproved = true;
        } else if (response._tag === 'Left') {
          component.resetProcess(true);
          return;
        }

        component.rotacionouEsquerdo = true;
      }

      if (component.rotacionouEsquerdo) {
        component.$emit('userWaved');
        disableCam();
        component.resetProcess(false);
      } else {
        return;
      }
    }

    function drawBlendShapes(el, blendShapes, landmarks) {
      if (!blendShapes.length) {
        return;
      }
      detectaAcao(blendShapes[0].categories, landmarks[0][152], landmarks);

      let htmlMaker = '';
      
      const shapes = [
        landmarks[0][152].x,
        landmarks[0][152].y,
        landmarks[0][152].z,
      ];
      shapes.forEach(shape => {
        htmlMaker += `
      <li class="blend-shapes-item">
        <span class="blend-shapes-label">${'T:'}</span>
        <span class="blend-shapes-value" style="width: calc(${Math.abs(shape) * 1000
          }% - 120px)">${(+shape).toFixed(4)}</span>
      </li>
    `;
      });

      el.innerHTML = htmlMaker;
    }
  },
  msg(text) {
    this.$toast(
      {
        component: ToastificationContent,
        props: {
          title: 'Atenção',
          icon: 'BellIcon',
          text: text,
          variant: 'danger',
        },
      },
      {
        position: 'top-right',
        timeout: 8000,
      }
    );
  },
  detectaAcao(categories) {
    const eyeBlinkLeft = categories.find(
      category => category.categoryName === 'eyeBlinkLeft'
    );
    const eyeBlinkRight = categories.find(
      category => category.categoryName === 'eyeBlinkRight'
    );
    const mouthSmileLeft = categories.find(
      category => category.categoryName === 'mouthSmileLeft'
    );
    const mouthSmileRight = categories.find(
      category => category.categoryName === 'mouthSmileRight'
    );

    const values = [
      eyeBlinkLeft,
      eyeBlinkRight,
      mouthSmileLeft,
      mouthSmileRight,
    ];

    values.forEach(val => {
      if (val.score > 0.5) {
        this.msg(val.categoryName);
      }
    });
  },
  methods: {
    sweetAlert(loading) {
      Swal.fire({
        title: 'Aguarde... carregando vídeo.',
        timer: loading,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          this.posicionouQueixo = true;
          this.showOlheCima = true;
          clearInterval(loading);
        },
        allowOutsideClick: false, // Bloqueia o fechamento ao clicar fora do SweetAlert
      });
    },
    enableCam(event) {
      this.sweetAlert(false);

      if (!this.faceLandmarker) {
        return;
      }
      const enableWebcamButton = this.$refs['webcamButton'];
      const video = this.$refs['webcam'];
      if (this.webcamRunning === true) {
        this.webcamRunning = false;
        enableWebcamButton.innerText = 'Iniciar detecção';
      } else {
        this.showImage = true;
        this.messageStart = true;
        this.webcamRunning = true;
        enableWebcamButton.innerText = 'Cancelar detecção';
      }

      // getUsermedia parameters.
      const constraints = {
        video: true,
      };

      // Activate the webcam stream.
      navigator.mediaDevices.getUserMedia(constraints).then(stream => {
        video.srcObject = stream;
        video.addEventListener('loadeddata', this.predictWebcam);
      });

      this.videoLoaded = false;
      this.messageStart = false;
    },
    async createFaceLandmarker() {
      const filesetResolver = await FilesetResolver.forVisionTasks(
        'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.3/wasm'
      );
      this.faceLandmarker = await FaceLandmarker.createFromOptions(
        filesetResolver,
        {
          baseOptions: {
            modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
            delegate: 'GPU',
          },
          outputFaceBlendshapes: true,
          runningMode: this.runningMode,
          numFaces: 1,
        }
      );
      // this.demosSection.classList.remove("invisible");
    },
    handleVideoLoaded() {
      this.sweetAlert(true);
      this.messageStart = true;
      this.videoLoaded = true;
    },
    resetProcess(acao = true) {
      this.posicionouQueixo = false;
      this.modalShow = false;
      // this.faceLandmarker = undefined
      this.demosSection = undefined;
      this.imageBlendShapes = undefined;
      this.videoBlendShapes = undefined;
      this.enableWebcamButton = undefined;
      this.webcamRunning = false;
      this.showImage = false;
      this.videoLoaded = false;
      this.messageStart = false;
      this.sorriu = false;
      this.piscou = false;
      this.abaixou = false;
      this.showOlheCima = false;
      this.showOlheBaixo = false;
      this.showOlheDireita = false;
      this.showOlheEsquerda = false;
      this.faceAproved = false;
      this.lastVideoTime = -1;
      this.levantou = false;
      this.showOlheBaixo = false;
      this.rotacionouDireito = false;
      this.rotacionouEsquerdo = false;
      // this.predictWebcam = undefined

      if(acao){
        this.videoPrint = undefined;
        this.finalizaEvent = false;
      }

      // Parar o stream da webcam
      const stream = this.$refs['webcam'].srcObject;
      if (stream) {
        this.$swal({
          title: 'Atenção!',
          text: 'Rosto diferente do identificado nas fotos tiradas anteriormente.',
          icon: 'warning',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'Fechar',
          buttonsStyling: false,
          customClass: {
            cancelButton: 'btn btn-danger ml-1',
          },
        }).then(async result => {
          if (!result.value) {
          }
        });

        const tracks = stream.getTracks();
        tracks.forEach(track => track.stop());
        this.$refs['webcam'].srcObject = null;
      }
    },
  },
};
</script>

<style>
@use '@material';

video {
  clear: both;
  display: block;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

section {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

header,
footer {
  clear: both;
}

.removed {
  display: none;
}

.invisible {
  opacity: 0.2;
}

.note {
  font-style: italic;
  font-size: 130%;
}

.videoView,
.detectOnClick,
.blend-shapes {
  position: relative;
  float: left;
  width: 48%;
  margin: 2% 1%;
  cursor: pointer;
}

.videoView p,
.detectOnClick p {
  position: absolute;
  padding: 5px;
  background-color: #007f8b;
  color: #fff;
  border: 1px dashed rgba(255, 255, 255, 0.7);
  z-index: 2;
  font-size: 12px;
  margin: 0;
}

.highlighter {
  background: rgba(0, 255, 0, 0.25);
  border: 1px dashed #fff;
  z-index: 1;
  position: absolute;
}

.canvas {
  z-index: 1;
  position: absolute;
  pointer-events: none;
}

.output_canvas {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.detectOnClick {
  z-index: 0;
}

.detectOnClick img {
  width: 100%;
}

.blend-shapes-item {
  display: flex;
  align-items: center;
  height: 20px;
}

.blend-shapes-label {
  display: flex;
  width: 120px;
  justify-content: flex-end;
  align-items: center;
  margin-right: 4px;
}

.blend-shapes-value {
  display: flex;
  height: 16px;
  align-items: center;
  background-color: #007f8b;
}

.arrow {
  position: absolute;
  bottom: 50%;
  left: 50%;
  margin-left: -20px;
}

.arrow:before,
.arrow:after {
  content: '';
  position: absolute;
  top: 0;
  right: -24px;
  width: 30px;
  height: 7px;
  border-radius: 10px;
  display: block;
  background: black;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.arrow:after {
  right: inherit;
  left: -24px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.animated {
  -webkit-animation: bounce 3s infinite;
  animation: bounce 3s both infinite;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-60px);
    opacity: 0;
  }
}

@-moz-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-60px);
    opacity: 0;
  }
}

@-o-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-60px);
    opacity: 0;
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    -webkit-transform: translateY(-60px);
    opacity: 0;
  }
}

.rounded-gray-box {
  background-color: gray;
  border-radius: 10px;
  padding: 10px;
  color: white;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.video-overlay-top {
  top: 80px;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 25%;
  content-visibility: visible;
  transform: rotate(270deg);
  /* Aqui você pode ajustar o ângulo de rotação desejado */
}

.video-overlay-down {
  top: 80px;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 25%;
  content-visibility: visible;
  transform: rotate(-270deg);
  /* Aqui você pode ajustar o ângulo de rotação desejado */
}

.video-overlay-left {
  top: 80px;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 25%;
  content-visibility: visible;
  transform: rotate(180deg);
  /* Aqui você pode ajustar o ângulo de rotação desejado */
}

.video-overlay-right {
  top: 80px;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 25%;
  content-visibility: visible;
  transform: rotate(0deg);
  /* Aqui você pode ajustar o ângulo de rotação desejado */
}
</style>
